export const cardsContent = [
  {
    id: 1,
    imageUrl: "../../assets/img/black-orlando.jpeg",
    text: "Emprendedores que Recomendamos y nos apoyan",
  },
  {
    id: 2,
    imageUrl: "../../assets/img/calcodelia.jpeg",
    text: "Empresas o emprendedores que Recomendamos y nos apoyan - Calcodelia - Mdq",
  },
  {
    id: 3,
    imageUrl: "../../assets/img/compton.jpeg",
    text: "Empresas o emprendedores que Recomendamos y nos apoyan - Compton - MDP",
  },
  {
    id: 4,
    imageUrl: "../../assets/img/delicius.jpeg",
    text: "Empresas o emprendedores que Recomendamos y nos apoyan - Delicius Académy - España",
  },
  {
    id: 5,
    imageUrl: "../../assets/img/detox.jpeg",
    text: "Empresas o emprendedores que Recomendamos y nos apoyan - Detox - Bs.As",
  },
  {
    id: 6,
    imageUrl: "../../assets/img/detox2.jpeg",
    text: "Empresas o emprendedores que Recomendamos y nos apoyan - Detox - Bs.As",
  },
  {
    id: 7,
    imageUrl: "../../assets/img/lui-fernandez.jpeg",
    text: "Lui Fernández - Tocando en La casa Cultural - India House Resistance",
  },
  {
    id: 8,
    imageUrl: "../../assets/img/garik.jpeg",
    text: "Nuestro residente GARIK en América Libre Centro Cultural",
  },
  {
    id: 9,
    imageUrl: "../../assets/img/dj1.jpeg",
    text: "Dj en evento de India House",
  },
  {
    id: 10,
    imageUrl: "../../assets/img/dj2.jpeg",
    text: "Dj en evento de India House",
  },
  {
    id: 11,
    imageUrl: "../../assets/img/dj3.jpeg",
    text: "Dj en evento de India House",
  },
  {
    id: 12,
    imageUrl: "../../assets/img/dj4.jpeg",
    text: "Dj en evento de India House",
  },
  {
    id: 13,
    imageUrl: "../../assets/img/dj5.jpeg",
    text: "Dj en evento de India House",
  },
  {
    id: 14,
    imageUrl: "../../assets/img/remera1.jpeg",
    text: "Transfers de remeras que hemos hechos para los djs",
  },
  {
    id: 15,
    imageUrl: "../../assets/img/remera2.jpeg",
    text: "Transfers de remeras que hemos hechos para los djs",
  },
  {
    id: 16,
    imageUrl: "../../assets/img/dj6.jpeg",
    text: "Dj en evento de India House",
  },
];

export const eventsCardContent = [
  {
    id: 1,
    imageUrl: "../../assets/img-eventos/as.jpeg",
    text: "",
  },
  {
    id: 2,
    imageUrl: "../../assets/img-eventos/basement.jpeg",
    text: "Empresas o emprendedores que Recomendamos y nos apoyan - Calcodelia - Mdq",
  },
  {
    id: 3,
    imageUrl: "../../assets/img-eventos/ciclo.jpeg",
    text: "Empresas o emprendedores que Recomendamos y nos apoyan - Compton - MDP",
  },
  {
    id: 4,
    imageUrl: "../../assets/img-eventos/expoCanabis.jpeg",
    text: "Empresas o emprendedores que Recomendamos y nos apoyan - Delicius Académy - España",
  },
  {
    id: 5,
    imageUrl: "../../assets/img-eventos/galo.jpeg",
    text: "Empresas o emprendedores que Recomendamos y nos apoyan - Detox - Bs.As",
  },
  {
    id: 6,
    imageUrl: "../../assets/img-eventos/garik2.jpeg",
    text: "Empresas o emprendedores que Recomendamos y nos apoyan - Detox - Bs.As",
  },
  {
    id: 7,
    imageUrl: "../../assets/img-eventos/gariksape.jpeg",
    text: "Lui Fernández - Tocando en La casa Cultural - India House Resistance",
  },
  {
    id: 8,
    imageUrl: "../../assets/img-eventos/gatos.jpeg",
    text: "Nuestro residente GARIK en América Libre Centro Cultural",
  },
  {
    id: 9,
    imageUrl: "../../assets/img-eventos/halloween.jpeg",
    text: "Dj en evento de India House",
  },
  {
    id: 10,
    imageUrl: "../../assets/img-eventos/valpiu.jpeg",

    text: "Dj en evento de India House",
  },
  {
    id: 11,
    imageUrl: "../../assets/img-eventos/urbano2.jpeg",
    text: "Dj en evento de India House",
  },
  {
    id: 12,
    imageUrl: "../../assets/img-eventos/roomie.jpeg",
    text: "Dj en evento de India House",
  },
  {
    id: 13,
    imageUrl: "../../assets/img-eventos/super.jpeg",
    text: "Dj en evento de India House",
  },
  {
    id: 14,
    imageUrl: "../../assets/img-eventos/urbano.jpeg",
    text: "Transfers de remeras que hemos hechos para los djs",
  },
  {
    id: 15,
    imageUrl: "../../assets/img-eventos/matrix.jpeg",
    text: "Transfers de remeras que hemos hechos para los djs",
  },
  {
    id: 16,
    imageUrl: "../../assets/img-eventos/nn.jpeg",
    text: "Dj en evento de India House",
  },
];
